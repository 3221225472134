import React from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { PageProps, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ReactPlayer from 'react-player';

import Layout, { LayoutOptions, HeroSectionColors } from '../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import { IconTypes } from '../../components/Icon/Icon';
import { useLocaleContext } from '../../context/Locale';
import CommonData from '../../data/common/common';
import { LocaleTag } from '../../../config/locales';
import { Accordion, AccordionItem } from '../../components/Accordion/Accordion';
import './style.scss';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import SmallImage from '../../components/Image/SmallImage/SmallImage';

type Props = PageProps & WithTranslation;

const Careers: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('seo.title'),
    },
    page: {
      title: t('heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-image-8.jpg',
          alt: 'Image',
        },
        blockText: t('tagline'),
        shouldBeDigiBlock: true,
      },
    },
  };

  const getWhatWeHaveToOfferRows = () => {
    const blocks: { heading: string; body: string }[] = t('whatWeHaveToOfferYou.blocks', {
      returnObjects: true,
    });

    return (
      <Accordion allowMultipleExpanded allowZeroExpanded>
        {blocks.map((block) => (
          <AccordionItem
            key={block.heading}
            buttonOptions={{
              label: block.heading,
              iconOptions: {
                open: IconTypes.ARROW_DOWN,
                closed: IconTypes.ARROW_NEXT,
              },
            }}
          >
            {block.body}
          </AccordionItem>
        ))}
      </Accordion>
    );
  };

  const dayforcehcmLink = CommonData.websites.dayForceHCM[activeLocaleTag];

  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query greatPlaceToWorkCareersQuery {
      allFile(filter: { relativePath: { regex: "/great-place-to-work/" }, ext: { eq: ".png" } }) {
        edges {
          node {
            publicURL
            childImageSharp {
              fluid(maxWidth: 200, quality: 100, fit: CONTAIN) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const {
    node: { publicURL },
  } =
    activeLocaleTag === LocaleTag.EN
      ? edges.find((n: any) => n.node.publicURL.match('great-place-to-work-2024-about-us.png'))
      : edges.find((n: any) => n.node.publicURL.match('great-place-to-work-2024-about-us-fr.png'));

  return (
    <Layout options={layoutOptions} className="Careers">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <div className="Careers__generic-gap">
              <h2 className="h3">{t('introBlock.heading')}</h2>
              <p>
                <Trans i18nKey="introBlock.description" t={t} />
              </p>
            </div>
            {/* <div className="Careers__generic-gap Careers__player-wrapper">
              <ReactPlayer
                url={CommonData.videos.yearReview2022[activeLocaleTag]}
                className="react-player"
                width="100%"
                height="100%"
                controls
              />
            </div> */}
          </section>
          <aside className="column column-33 Careers__current-opportunities">
            <div className="Careers__generic-gap">
              <h2 className="h3">{t('currentOpportunitiesBlock.heading')}</h2>
              <ButtonLink
                to={dayforcehcmLink}
                linkOptions={{ isExternalLink: true }}
                styleOptions={{ invertColors: true }}
                iconOptions={{
                  icon: IconTypes.ARROW_NEXT,
                }}
              >
                {t('currentOpportunitiesBlock.buttonLabel')}
              </ButtonLink>
            </div>
            <div className="Careers__generic-gap">
              <div className="small-image-container">
                <img
                  src={publicURL}
                  alt={t(`${LocaleNameSpaceKeys.COMMON}:ariaLabels.gptwImage`)}
                />
              </div>
            </div>
          </aside>
        </div>

        <div className="Careers__generic-gap">
          <h2>{t('ourVision.heading')}</h2>
          <p>{t('ourVision.description')}</p>
        </div>

        <div className="Careers__generic-gap">
          <h2>{t('ourValues.heading')}</h2>

          <div className="row row-wrap">
            {(t('ourValues.sections', { returnObjects: true }) as {
              heading: string;
              description: string;
              icon: IconTypes;
            }[]).map((section) => (
              <div className="column column-30 mb-2" key={section.heading}>
                <InfoBlock heading={section.heading} icon={IconTypes[section.icon]}>
                  {section.description}
                </InfoBlock>
              </div>
            ))}
          </div>
        </div>

        <div className="Careers__generic-gap">
          <h2 className="Careers__generic-gap h3">{t('whatWeHaveToOfferYou.heading')}</h2>
          {getWhatWeHaveToOfferRows()}
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CAREERS)(Careers);
